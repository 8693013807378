var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "The firm",
            "tabActive": "비전"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("T")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("he Firm")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--sm page-section--first",
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_c('v-img', {
          staticClass: "w-100 mx-auto",
          attrs: {
            "data-aos": "fade-up",
            "src": "/images/sub/firm/vision/vision-img.jpg",
            "max-width": "1920",
            "aspect-ratio": 1920 / 500
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-40px mt-md-80px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--lg font-weight-bold line-height-14",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 소셜임팩트 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-96px"
  }, _vm._l(_vm.info, function (detail, index) {
    return _c('v-row', {
      key: index,
      class: index !== 0 ? 'mt-16px mt-md-32px' : '',
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "data-aos": "fade-up",
        "cols": "auto"
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-regular secondary--text pr-10px pr-md-32px"
    }, [_vm._v("0" + _vm._s(index + 1))])], 1), _c('v-col', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_c('u-txt-default', {
      staticClass: "txt--lg"
    }, [_vm._v(" " + _vm._s(detail) + " ")])], 1)], 1);
  }), 1)])], 1)], 1)]), _c('page-section', {
    staticClass: "pt-0 page-section--last"
  }, [_c('v-row', {
    staticClass: "border-t border-l border-b",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.cert, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "border-r",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "pa-20px pa-md-30px py-lg-60px px-lg-58px"
    }, [_c('v-img', {
      staticClass: "w-100 mx-auto",
      attrs: {
        "data-aos": "fade-up",
        "src": item.image,
        "max-width": "900",
        "aspect-ratio": 900 / 600
      }
    }), _c('u-tit-default', {
      staticClass: "tit--sm font-weight-bold line-height-14 text-center mt-16px mt-md-32px",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    })])], 1)]);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }