<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="The firm" tabActive="비전">
                <template #titHead>T</template>
                <template #tit>he Firm</template>
            </sub-visual>
        </template>
        <page-section class="page-section--sm page-section--first">
            <template #pageSectionHead>
                <v-img data-aos="fade-up" src="/images/sub/firm/vision/vision-img.jpg" max-width="1920" :aspect-ratio="1920 / 500" class="w-100 mx-auto" />
            </template>
            <div class="mt-40px mt-md-80px">
                <v-row>
                    <v-col cols="12" md="6">
                        <u-tit-default data-aos="fade-up" class="tit--lg font-weight-bold line-height-14">
                            소셜임팩트
                        </u-tit-default>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="pl-md-20px pl-lg-96px">
                            <v-row v-for="(detail, index) in info" :key="index" :class="index !== 0 ? 'mt-16px mt-md-32px' : ''" no-gutters>
                                <v-col data-aos="fade-up" cols="auto">
                                    <u-tit-default class="tit--sm font-weight-regular secondary--text pr-10px pr-md-32px">0{{index+1}}</u-tit-default>
                                </v-col>
                                <v-col data-aos="fade-up" data-aos-delay="200">
                                    <u-txt-default class="txt--lg">
                                        {{detail}}
                                    </u-txt-default>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </page-section>
        <page-section class="pt-0 page-section--last">
            <v-row no-gutters class="border-t border-l border-b">
                <v-col v-for="(item, index) in cert" :key="index" cols="12" class="border-r">
                    <div class="pa-20px pa-md-30px py-lg-60px px-lg-58px">
                        <v-img data-aos="fade-up" :src="item.image" max-width="900" :aspect-ratio="900 / 600" class="w-100 mx-auto" />
                        <u-tit-default data-aos="fade-up" class="tit--sm font-weight-bold line-height-14 text-center mt-16px mt-md-32px"><span v-html="item.title"></span></u-tit-default>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            info: [
                "법을 잘 아는 사람은 수십, 수백 억의 대금이 수수되는 매매계약을 혼자 체결해냅니다. 매매계약 체결 과정에서 수백 개에 이르는 특약조항의 의미가 유리한지 불리한지도 스스로 해석하고, 분쟁을 최소화할 수 있도록 자신에게 유리한 문구로 고칠 줄도 압니다. 이렇게 매수한 집의 인테리어 공사계약도 혼자서 척척 체결하고, 인테리어 하자가 발생하더라도 당황하지 않고 하자분쟁을 부드럽게 처리해냅니다.",
                "법을 잘 아는 사람은 자식이 학교폭력으로 울고 집에 돌아와도 화 한 번 내지 않고 해결하고, 부득이 교통사고를 내고도 혼자 척척 대처하며, 억울하게 사건의 범인으로 몰렸을 때도 혼자서도 억울한 누명을 차분하게 벗을 방법을 생각해냅니다.",
                "법을 잘 아는 사람은 단순히 법조문을 잘 외우는 사람이 아닙니다. 이 분쟁이 법정에 끝까지 갔을 때 판결의 결론을 미리 잘 예측하는 사람입니다. 결론을 예측한 사람은 미리 갈등의 씨앗을 제거하고, 갈등의 크기를 최소화하고며, 피해의 정도도 최소화합니다. 판결의 결과를 아는 사람은 당황하지 않습니다. 앞으로 어떻게 대처해야 하는지 잘 알고 있기 떄문입니다. 판결의 결과를 예측하는 사람은 자신감을 가지고 자신의 일과 성공에 매진할 수 있습니다.",
                "법은, 사회적으로는 시민 간의 갈등을 해결하는 수단임과 동시에 개인적으로는 본인의 권리를 지키고 보호하는 최고의 방어장치인 것입니다.",
            ],
            cert: [
                {
                    title: "소셜임팩트, 판심의 3가지 프로그램",
                    image: "https://www.judgemind.com/img/1.jpg",
                },
                {
                    title: "노인·성인 동반성장 프로그램",
                    image: "https://www.judgemind.com/img/2.jpg",
                },
                {
                    title: "장학생 지원 프로그램",
                    image: "https://www.judgemind.com/img/3.jpg",
                },
                {
                    title: "유관기관 협력 프로그램",
                    image: "https://www.judgemind.com/img/4.jpg",
                },
                {
                    title: "한국디지털포렌식센터",
                    image: "/images/sub/firm/vision/vision-agreement-01.jpg",
                },
                {
                    title: "경기교사노동조합",
                    image: "/images/sub/firm/vision/vision-agreement-02.jpg",
                },
                {
                    title: "유앤문 정신건강의학과",
                    image: "/images/sub/firm/vision/vision-agreement-03.jpg",
                },
                {
                    title: "부모와 아이 마음 의원",
                    image: "/images/sub/firm/vision/vision-agreement-04.jpg",
                },
                {
                    title: "우영회계법인",
                    image: "/images/sub/firm/vision/vision-agreement-05.jpg",
                },
                {
                    title: "최상산부인과",
                    image: "/images/sub/firm/vision/vision-agreement-06.jpg",
                },
            ]
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
