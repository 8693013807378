var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Contact Us",
            "tabActive": "대구분사무소"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("C")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("ontact Us")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('sub-contact', {
    attrs: {
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.4804225545263!2d128.62319307721492!3d35.861741920034696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3565e2249622726f%3A0x265c1074a886d783!2z64yA6rWs6rSR7Jet7IucIOyImOyEseq1rCDrj5nrjIDqtazroZwgMzQ1!5e0!3m2!1sko!2skr!4v1730456124946!5m2!1sko!2skr",
      "address": "대구 수성구 동대구로 345 우방유쉘상가동 2층 206호",
      "image": "/images/sub/contact/incheon/daegu-img.jpg"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }