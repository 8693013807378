<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Contact Us" tabActive="대구분사무소">
                <template #titHead>C</template>
                <template #tit>ontact Us</template>
            </sub-visual>
        </template>
        <sub-contact
        iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.4804225545263!2d128.62319307721492!3d35.861741920034696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3565e2249622726f%3A0x265c1074a886d783!2z64yA6rWs6rSR7Jet7IucIOyImOyEseq1rCDrj5nrjIDqtazroZwgMzQ1!5e0!3m2!1sko!2skr!4v1730456124946!5m2!1sko!2skr"
        address="대구 수성구 동대구로 345 우방유쉘상가동 2층 206호"
        image="/images/sub/contact/incheon/daegu-img.jpg" 
         />
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import SubContact from "@/sets/styles/sub/sub-contact.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        SubContact,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
