import { render, staticRenderFns } from "./u-tit-wrap-line.vue?vue&type=template&id=736d05ed&scoped=true"
import script from "./u-tit-wrap-line.vue?vue&type=script&lang=js"
export * from "./u-tit-wrap-line.vue?vue&type=script&lang=js"
import style0 from "./u-tit-wrap-line.vue?vue&type=style&index=0&id=736d05ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736d05ed",
  null
  
)

export default component.exports